@font-face {
  font-family: JetBrainsMono;
  src: url("./fonts/JetBrainsMono.ttf");
}

html {
  /* width: 100vw; */
  height: 100vh;
}

#root {
  height: 100vh;
  /* width: 100vw; */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.cc-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  line-height: 24px;
  border-bottom: 1px solid #d8d8d8;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(7px);
  z-index: 99;
}

.cc-header .logo {
  font-size: 20px;
  background: linear-gradient(to right, #00a0e9, #0066ff);
  color: transparent;
  background-clip: text;
  font-weight: bold;
  line-height: 32px;
  user-select: none;
}

.cc-header .func {
  display: flex;
  align-items: center;
  line-height: 24px;
  z-index: 9;
}

.cc-header .func .progress {
  width: 140px;
}

.cc-header .func .info {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
  color: #a9a9a9;
  line-height: 24px;
  white-space: nowrap;
}

.cc-card {
  position: relative;
  border: 1px solid rgba(28, 31, 35, 0.08);
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s;
  margin-bottom: 16px;
}

.cc-card:hover {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 4px 14px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.cc-card .header {
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 0 8px;
}

.cc-card .header .title {
  padding: 0 8px;
  font-weight: bold;
  cursor: pointer;
  line-height: 32px;
  overflow: auto;
}

.cc-card .header .func {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(7px);
  border-radius: 6px;
}

.cc-card .footer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 8px 16px;
  font-size: 10px;
  color: #cfcfcf;
  user-select: none;
}

.cc-card .header .title:hover {
  border-radius: 6px;
  background: rgba(28, 31, 35, 0.08);
}

.cc-card .content {
  padding: 8px 16px;
  /* max-height: 200px; */
  overflow-x: auto;
  overflow-y: auto;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(28, 31, 35, 0.08);
}

.cc-footer {
  text-align: center;
  color: #e0e0e0;
}

.cc-search{
  overflow: hidden;
  transition: all 0.3s;
}